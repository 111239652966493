import React, {useEffect} from 'react';
import './App.css';
import ReactGA from "react-ga4";
import {Route, Routes} from "react-router-dom";
import {HOME_URL, PIXIE_SUPPORT_URL} from "../../constants/application/ApplicationUrl";
import CarPoolUrlComponent from "../screens/carpool/CarPoolUrlComponent";
import {CAR_POOL_HOME_URL} from "../screens/carpool/CarPoolUrl";
import {TWISTER_HOME} from "../screens/twister/TwisterUrl";
import TwisterUrlComponent from "../screens/twister/TwisterUrlComponent";
import {PixieMartSupport} from "../support/PixieMartSupport";
import {StaticWebHome} from "../screens/home/StaticWebHome";
import {INSPIRE_HOME_URL} from "../screens/inspire/InspireUrl";
import {InspireUrlComponent} from "../screens/inspire/InspireUrlComponent";
import {REDIRECT_HOME_URL} from "../screens/redirects/RedirectsUrl";
import {RedirectUrlComponent} from "../screens/redirects/RedirectUrlComponent";
import VentItOutUrlComponent from "../screens/ventitout/VentItOutUrlComponent";
import {VENT_IT_OUT_HOME} from "../screens/ventitout/VentItOutUrl";

function App() {
    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search, title: window.document.title});
    }, []);

    return (
        <div className="App d-flex flex-column">
            <div className={"flex-fill d-flex flex-column"}>
                <Routes>
                    <Route path={HOME_URL + "/*"} element={<StaticWebHome />}/>
                    <Route path={PIXIE_SUPPORT_URL} element={<PixieMartSupport />}/>
                    <Route path={CAR_POOL_HOME_URL + "/*"} element={<CarPoolUrlComponent />} />
                    <Route path={TWISTER_HOME + "/*"} element={<TwisterUrlComponent />} />
                    <Route path={INSPIRE_HOME_URL + "/*"} element={<InspireUrlComponent />} />
                    <Route path={VENT_IT_OUT_HOME + "/*"} element={<VentItOutUrlComponent />} />
                    <Route path={REDIRECT_HOME_URL + "/*"} element={<RedirectUrlComponent />} />
                </Routes>
            </div>
        </div>
    );
}

export default App;
