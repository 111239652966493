import {Button, FormControl} from "react-bootstrap";

export function PositiveBlock() {
    return <div className={"bg-white p-3 my-3 rounded shadow-sm border"}>
        <form onSubmit={SubmitForm}>
            <h4>
                <label className={"small fw-bold"}>
                    What is one positive thing that happened today, no matter how small, that made you feel good or
                    brought
                    a smile to your face 😊?
                </label>
            </h4>
            <FormControl className={"shadow-none small"} as={"textarea"} name={"good_content"}
                         placeholder={"This question encourages you to reflect on the positive moments of your day, even if they seem small. By focusing on what went well, you'll start to cultivate a mindset of gratitude and appreciation, helping you recognize the good things in life more easily, no matter how simple they may be. \n😊😊😊"}
                         rows={10}/>
            <div className={"text-center pt-3"}>
                <Button type={"submit"} className={"rounded-5 w-25"}>Submit</Button>
            </div>
        </form>
    </div>;
}

function SubmitForm(event: any) {
    event.preventDefault();
    alert("Work in progress");
    return false;
}
