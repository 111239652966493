import {Button, FormControl} from "react-bootstrap";

export function NegativeBlock() {
    return <div className={"bg-white p-3 my-3 rounded shadow-sm border"}>
        <form onSubmit={SubmitForm}>
            <h3>
                <label className={"small fw-bold"}>
                    What is one challenge or setback you faced today that made you feel frustrated or upset 😠?
                </label>
            </h3>
            <FormControl className={"shadow-none small"} as={"textarea"} name={"good_content"}
                         placeholder={"This question helps you identify and express the difficulties or frustrations you encountered today. By acknowledging what went wrong, you can release pent-up emotions and begin to process these experiences, paving the way for personal growth and emotional resilience. \n😠😠😠"}
                         rows={10}/>
            <div className={"text-center pt-3"}>
                <Button type={"submit"} className={"rounded-5 w-25"}>Submit</Button>
            </div>
        </form>
    </div>
}

function SubmitForm(event: any) {
    event.preventDefault();
    alert("Work in progress");
    return false;
}
