import {PositiveBlock} from "./PositiveBlockComponent";
import {NegativeBlock} from "./NegativeBlockComponent";
import {VentItOutFooter} from "../footer/VentItOutFooter";

export function VentItOutHome() {
    const currentDate = new Date();

    // Manually format the date and time
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = currentDate.toLocaleString("en-US", { month: "short" });
    const year = currentDate.getFullYear().toString().slice(-2);
    const time = currentDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    }).toLowerCase(); // This ensures "am/pm" is lowercase

    const formattedDate = `${day} ${month} ${year}, ${time}`;

    return <section className={"d-flex flex-fill flex-column bg-light-subtle"}>
        <div className={"container pb-5"}>
            <div className={"text-center"}>
                <h1 className={"fw-bold"}>Vent it Out!</h1>
                <p className={"fw-bold text-secondary"}>{formattedDate}</p>
            </div>
            <PositiveBlock />
            <NegativeBlock />
        </div>

        <VentItOutFooter />
    </section>
}
