import Footer from "../../../footer/Footer";
import {Policies} from "../../../footer/FooterInterface";
import {VENT_IT_OUT_DISCLAIMER_URL, VENT_IT_OUT_FAQ_URL, VENT_IT_OUT_HOME, VENT_IT_OUT_PRIVACY_POLICY_URL} from "../VentItOutUrl";

export function VentItOutFooter({hidePolicies}:{hidePolicies?: boolean;}) {
    return <section>
        <Footer contactUsName={"Pixie Mart - Vent It Out"}
                faqUrl={VENT_IT_OUT_HOME + VENT_IT_OUT_FAQ_URL}
                policies={policies}
                hidePolicies={hidePolicies} />
    </section>
}

const policies: Policies[] = [
    {
        name: "Privacy Policy",
        link: VENT_IT_OUT_HOME + VENT_IT_OUT_PRIVACY_POLICY_URL
    },
    {
        name: "Disclaimer",
        link: VENT_IT_OUT_HOME + VENT_IT_OUT_DISCLAIMER_URL
    }
]
