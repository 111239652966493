import React, {Component} from "react";
import {Route, Routes} from "react-router-dom";
import {HOME_URL} from "../../../constants/application/ApplicationUrl";
import {SetTongueTwisterDocumentTitle} from "../../../managers/DocumentTitleManager";
import {VentItOutHome} from "./home/VentItOutHome";

class VentItOutUrlComponent extends Component {
    componentDidMount() {
        SetTongueTwisterDocumentTitle("Home","Enhance your speech with Pixie Mart - Tongue Twister 🌟 Master the art of pronunciation through challenging twists and turns. Fun, engaging, and perfect for achieving fluency. Start your journey to speech perfection today.");
    }

    render() {
        return (
            <Routes>
                <Route path={HOME_URL} element={<VentItOutHome />}/>
                {/*<Route path={TWISTER_APP_DOWNLOAD_URL} element={<TwisterApplicationDownload />} />*/}

                {/*<Route path={TWISTER_POLICIES_BASE_URL} >*/}
                {/*    <Route path={TWISTER_PRIVACY_POLICY_URL} element={<TongueTwisterPrivacyPolicy />} />*/}
                {/*    <Route path={TWISTER_DISCLAIMER_URL} element={<TongueTwisterDisclaimerPolicy />} />*/}
                {/*</Route>*/}

                {/*<Route path={TWISTER_FAQ_URL} >*/}
                {/*    <Route path={TWISTER_FAQ_URL} element={<TongueTwisterFAQ />} />*/}
                {/*</Route>*/}
            </Routes>
        );
    }
}

export default VentItOutUrlComponent;
